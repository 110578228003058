window.addEventListener('DOMContentLoaded', async () => {
    const headerElements = document.querySelectorAll('.ce_accordion > .toggler');
    const contentElements = [];

    if (!headerElements.length) {
        return;
    }

    const { default: Handorgel } = await import('handorgel');

    headerElements.forEach((toggler) => {
        const button = document.createElement('button');
        button.type = 'button';
        button.append(...toggler.childNodes);
        toggler.appendChild(button);
        toggler.parentNode.classList.add('handorgel');
        contentElements.push(toggler.nextElementSibling);

        if (window.location.hash === `#${toggler.parentNode.id}`) {
            toggler.dataset.open = true;
        }
    });

    const handorgel = new Handorgel(document.body, {
        headerElements,
        contentElements,
        headerOpenClass: 'open',
        contentOpenClass: 'open',
        headerOpenedClass: 'active',
        contentOpenedClass: 'active',
        headerFocusClass: 'focus',
        contentFocusClass: 'focus',
        headerDisabledClass: 'disabled',
        contentDisabledClass: 'disabled',
    });

    handorgel.on('fold:open', (fold) => {
        window.history.pushState(null, null, `${window.location.href.replace(/#.+$/, '')}#${fold.header.parentNode.id}`);
    });

    handorgel.on('fold:close', (fold) => {
        if (window.location.hash === `#${fold.header.parentNode.id}`) {
            window.history.pushState(null, null, `${window.location.href.replace(/#.+$/, '')}`);
        }
    });

    window.addEventListener('hashchange', () => {
        headerElements.forEach((toggler, index) => {
            if (window.location.hash === `#${toggler.parentNode.id}`) {
                handorgel.folds[index].open();
            }
        });
    });

});
