import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['sticky'];

    async connect () {
        const { gsap } = await import('gsap');
        const { ScrollTrigger } = await import('gsap/Scrolltrigger');

        gsap.registerPlugin(ScrollTrigger);

        if (ScrollTrigger.positionInViewport(this.element.parentNode, 'bottom') < 0) {
            this.element.classList.add(this.stickyClass);
        }

        // add a timeout so the page scrolls before ScrollTrigger is instantiated
        setTimeout(() => {
            this.trigger = ScrollTrigger.create({
                trigger: this.element.parentNode,
                start: "bottom top",

                onEnter: () => {
                    this.element.classList.add(this.stickyClass);
                },

                onEnterBack: () => {
                    this.element.classList.remove(this.stickyClass);
                }
            });
        }, 1000)
    }

    disconnect () {
        this.trigger && this.trigger.kill();
    }
}
