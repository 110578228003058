import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['class'];
    static classes = ['toggle', 'body'];

    toggle (event) {
        if (this.hasClassTarget) {
            this.classTarget.classList.toggle(this.toggleClass)
        } else {
            document.body.classList.toggle(this.toggleClass)
        }

        if (this.hasBodyClass) {
            document.body.classList.toggle(this.bodyClass);
        }
    }

    add (event) {
        if (this.hasClassTarget) {
            this.classTarget.classList.add(this.toggleClass)
        } else {
            document.body.classList.add(this.toggleClass)
        }

        if (this.hasBodyClass) {
            document.body.classList.add(this.bodyClass);
        }
    }

    remove (event) {
        if (this.hasClassTarget) {
            this.classTarget.classList.remove(this.toggleClass)
        } else {
            document.body.classList.remove(this.toggleClass)
        }

        if (this.hasBodyClass) {
            document.body.classList.remove(this.bodyClass);
        }
    }
}
