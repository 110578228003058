import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['block', 'body'];

    async loadScripts () {
        await new Promise((resolve) => {
            const scripts = [
                '/layout/plugins/flip-book/js/libs/jquery.min.js',
                '/layout/plugins/flip-book/js/libs/html2canvas.min.js',
                '/layout/plugins/flip-book/js/libs/three.min.js',
                '/layout/plugins/flip-book/js/libs/pdf.min.js',
            ];

            let done = scripts.length;

            scripts.forEach((src) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => {
                    done--;
                    if (done <= 0) {
                        resolve();
                    }
                }
                document.head.append(script);
            });
        });

        window.PDFJS_LOCALE = {
            pdfJsWorker: '/layout/plugins/flip-book/js/pdf.worker.js',
            pdfJsCMapUrl: '/layout/plugins/flip-book/cmaps'
        };

        window.FB3D_LOCALE = {
            dictionary: {
                'Loading...': 'Laden...',
                'Please wait... the Application is Loading': 'Bitte warten... das Programm wird geladen',
                'PDF is Loading:': 'Lade PDF:',
            }
        };

        await new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = '/layout/plugins/flip-book/js/dist/3dflipbook.js';
            script.onload = () => { resolve(); }
            document.head.append(script);
        });
    }

    disconnect () {
        this.$book?.dispose();
    }

    async open () {
        const darkMode = !!window.matchMedia('(prefers-color-scheme: dark)')?.matches;

        this.$modal = document.createElement('div');
        this.$modal.classList.add(this.blockClass);

        const container = document.createElement('div');
        container.classList.add(`${this.blockClass}__container`);

        const image = document.createElement('img');
        image.src = '/layout/plugins/flip-book/images/page-dark-loader.gif';
        image.width = 99;
        image.height = 99;
        container.append(image);

        const button = document.createElement('button');
        button.classList.add(`${this.blockClass}__close`);
        button.title = 'Schliessen';
        button.addEventListener('click', () => {
            this.$book.dispose();
            this.$modal.remove();

            if (this.hasBodyClass) {
                document.body.classList.remove(this.bodyClass);
            }
        });

        this.$modal.append(button, container);
        document.body.append(this.$modal);

        if (this.hasBodyClass) {
            document.body.classList.add(this.bodyClass);
        }

        if (!window.jQuery) {
            await this.loadScripts();
        }

        this.$book = window.jQuery(container).FlipBook({
            pdf: this.element.href,
            controlsProps: {
                downloadURL: this.element.href,
                actions: {
                    cmdShare: {
                        enabled: false,
                    },
                },
            },
            template: {
                html: '/layout/plugins/flip-book/templates/default-book-view.html',
                styles: [
                    `/layout/plugins/flip-book/css/${darkMode ? 'black' : 'white'}-book-view.css`
                ],
                links: [{
                    rel: 'stylesheet',
                    href: '/layout/plugins/flip-book/css/font-awesome.min.css'
                }],
                script: ['/layout/plugins/flip-book/js/default-book-view.js'],
                printStyle: undefined, // or you can set your stylesheet for printing ('print-style.css')
                sounds: {
                    startFlip: '/layout/plugins/flip-book/sounds/start-flip.mp3',
                    endFlip: '/layout/plugins/flip-book/sounds/end-flip.mp3'
                }
            },
        });
    }
}
