
window.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.widget > input.text, .widget > textarea').forEach((el) => {
        if (el.getAttribute('placeholder')) {
            return;
        }

        const widget = el.parentNode;
        const detect = () => {
            el.value ? widget.classList.remove('empty') : widget.classList.add('empty');
        };

        el.addEventListener('focus', () => {
            widget.classList.remove('empty');
        });

        el.addEventListener('blur', detect);
        el.addEventListener('change', detect);
        detect();
    });

    // document.querySelectorAll('.widget > select').forEach((el) => {
    //     if (el.querySelector('option').value === '') {
    //         return;
    //     }
    //
    //     const update = () => {
    //         el.classList[el.value ? 'remove' : 'add']('empty');
    //     };
    //
    //     el.addEventListener('change', update);
    //     update();
    // });
});
