import { Controller } from '@hotwired/stimulus';
import 'tiny-slider/src/tiny-slider.scss';

export default class extends Controller {
    static targets = ['container'];

    static values = {
        responsive: { type: Boolean, default: false },
        controls: { type: Boolean, default: false },
        navigation: { type: Boolean, default: true },
        speed: { type: Number, default: 1000 },
        autoplay: { type: Boolean, default: false },
    }

    async connect () {
        if (!this.hasContainerTarget) {
            return;
        }

        const { tns: TinySlider } = await import('tiny-slider/src/tiny-slider.module');

        this.slider = TinySlider({
            container: this.containerTarget,
            slideBy: 'page',
            controls: this.controlsValue,
            controlsPosition: 'bottom',
            nav: this.navigationValue,
            navContainer: this.hasNavigationTarget ? this.navigationTarget : false,
            navPosition: 'bottom',
            navAsThumbnails: this.hasNavigationTarget,
            speed: this.speedValue,
            autoplay: this.autoplayValue,
            autoplayButtonOutput: false,
            autoplayHoverPause: true,
            loop: false,
            autoHeight: false,
            responsive: this.responsiveValue ? {
                600: {
                    items: 2,
                },
                1220: {
                    items: 3
                }
            } : null,
        });
    }

    disconnect () {
        if (this.slider) {
            this.slider.destroy();
        }
    }
}
