import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['item', 'title', 'widget'];

    connect () {
        let scrollTo = false;
        this.years = [];

        this.itemTargets.forEach((el) => {
            this.years.push(el.dataset.year);
        });

        this.years = this.years.filter((v, i) => this.years.indexOf(v) === i); // remove duplicates
        this.years.sort((a, b) => b - a); // sort by years descending

        if (window.location.hash) {
            scrollTo = true;
            this.currentYear = window.location.hash.substring(1);
        } else {
            this.currentYear = this.years[0];
        }

        this.filter(this.currentYear);

        let markup = '';
        this.years.forEach((year) => {
            markup = `${markup}<option value="${year}"${this.currentYear == year ? ' selected' : ''}>Ausgaben ${year}</option>`
        })

        const widget = document.createElement('select');
        widget.innerHTML = markup;

        widget.addEventListener('change', () => {
            this.filter(widget.value);
        })

        this.widgetTarget.append(widget);
        this.widgetTarget.classList.add('active');

        if (scrollTo) {
            this.element.scrollIntoView()
        }
    }

    filter (year) {
        this.titleTarget.innerText = `Ausgaben ${year}`;
        window.location.hash = `#${year}`;
        this.itemTargets.forEach((el) => {
            el.style.display = el.dataset.year == year ? 'block' : 'none';
        });
    }
}
